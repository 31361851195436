<template>
  <section>
    <el-button
      :loading="createDepartmentLoading"
      type="primary"
      size="mini"
      icon="el-icon-plus"
      @click="newDepartment"
      >Add department</el-button
    >
    <el-row :gutter="10" span="12">
      <!-- New department popup -->
      <department-form-dialog
        :visible.sync="departmentFormDialogVisible"
        :props-model="formModel"
        :departments="departments"
        @submit:department="submitDepartment"
      />

      <departments-list :departments="departments" @edit:department="editDepartment" />
    </el-row>
  </section>
</template>

<script>
import _, { find } from "lodash";
import DepartmentFormDialog from "./DepartmentFormDialog.vue";
import DepartmentsList from "./DepartmentsList.vue";
import { mapGetters } from "vuex";

const DEPARTMENTS_CREATE = require("@/graphql/DepartmentsCreate.graphql");
const DEPARTMENTS_UPDATE = require("@/graphql/DepartmentsUpdate.graphql");

export default {
  components: {
    DepartmentFormDialog,
    DepartmentsList,
  },
  data() {
    return {
      formDepartmentId: undefined,
      departmentFormDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["createDepartmentLoading"]),
    formModel() {
      const department = find(this.departments, (d) => d.id === this.formDepartmentId);
      return department;
    },
    departments() {
      return _.get(this.$store, "state.department.departments", []);
    },
  },
  methods: {
    newDepartment() {
      this.formDepartmentId = undefined;
      this.departmentFormDialogVisible = true;
    },
    editDepartment(department) {
      this.formDepartmentId = department.id;
      this.departmentFormDialogVisible = true;
    },
    submitDepartment(department) {
      this.$store.commit("SET_CREATE_LOADING", true);
      if (department.id) {
        const departmentForm = _.pick(department, ["id", "name", "description", "meta"]);
        this.updateDepartment(departmentForm);
      } else {
        const departmentForm = _.pick(department, ["name", "description", "meta"]);
        this.createDepartment(departmentForm);
      }
    },
    createDepartment(department) {
      const self = this;
      department.name = department.name.trim();
      this.$apollo
        .mutate({
          mutation: DEPARTMENTS_CREATE,
          variables: {
            department,
          },
          update(store, { data: { agentAPI } }) {
            const created = _.get(agentAPI, "createDepartment", null);
            if (created) {
              self.$store.dispatch("FETCH_DEPARTMENTS");
            }
            self.$store.commit("SET_CREATE_LOADING", false);
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully created the ${department.name} department!`,
            });
          },
        })
        .catch((error) => {
          self.$store.commit("SET_CREATE_LOADING", false);
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: error.message,
          });
        });
    },
    updateDepartment(department) {
      const self = this;
      department.name = department.name.trim();
      this.$apollo
        .mutate({
          mutation: DEPARTMENTS_UPDATE,
          variables: {
            department,
            agents: department.agents,
          },
          update(store, { data: { agentAPI } }) {
            self.$store.commit("SET_CREATE_LOADING", false);
            self.$store.commit("DEPARTMENT_UPDATE", department);
            self.$notify.success({
              title: "Success",
              position: "bottom-right",
              message: `Successfully updated the ${department.name} department!`,
            });
          },
        })
        .catch((error) => {
          self.$store.commit("SET_CREATE_LOADING", false);
          self.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: error.message,
          });
        });
    },
  },
};
</script>
